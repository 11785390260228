import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route, Redirect, useParams } from 'react-router-dom';

const RequestConfirmation = lazy(() => import('../views/RequestConfirmation'));
const Success = lazy(() => import('../views/Success'));
const Reschedule = lazy(() => import('../views/Reschedule'));
const Cancelled = lazy(() => import('../views/Cancelled'));

const Router: React.FC = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={<div>Loading</div>}>
                <Switch>
                    <Route path="/appointment/request-confirmation/:appointmentId/:notificationId?" exact render={(props) => <RequestConfirmation {...props} />}  >
                    </Route>
                    <Route path="/appointment/success/:appointmentId/:notificationId?" exact render={(props) => <Success {...props} />}  >
                    </Route>
                    <Route path="/appointment/cancelled/:appointmentId/:notificationId?" exact render={(props) => <Cancelled {...props} />}  >
                    </Route>
                    <Route path="/appointment/reschedule/:appointmentId/:notificationId?" exact render={(props) => <Reschedule {...props} />}  >
                    </Route>
                </Switch>

            </Suspense>
        </BrowserRouter>
    );
}
export default Router;